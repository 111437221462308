import React, { useEffect, useState } from 'react'
import { GoBackButton } from '../../../utils'
import { useParams} from "react-router-dom";
import Playbook from "../../../models/dto/playbook";
import {fetchPlaybook} from "../../../database/playbookQueries";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import EditPlaybookForm from "../../../components/forms/playbook/editPlaybookForm";

export default function EditPlaybook() {

    let {playbookId} = useParams<{playbookId: string}>();

    const supabase = useSupabaseClient()
    const [oldPlaybook, setOldPlaybook] = useState<Playbook>()

    useEffect(() => {
        const fetch_playbook = async () => {
            const pb = await fetchPlaybook(supabase, playbookId ? playbookId : "");
            setOldPlaybook(pb.playbook)
        };
        const _response = fetch_playbook()
    },[])

    const [newPlaybook, setPlaybook] = useState<Playbook>();

    return <>
      <h1 className="text-3xl font-bold">Document Type Edition Form</h1>
        { oldPlaybook && <EditPlaybookForm setPlaybook={setPlaybook} playbook={oldPlaybook!}/>}
      
      <GoBackButton text={"Cancel"}/>
    </>

}
